<template>
  <div>
    <el-row :gutter="20" class="mb-5" type="flex" justify="space-between">
      <el-col :span="8">
        <div class="block">
          <el-date-picker v-model="filters.dates" type="daterange" align="right" start-placeholder="Start Date"
            end-placeholder="End Date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable @change="onInit()">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="4">
        <p class="mt-2">{{ itemsShowing }}</p>
      </el-col>
    </el-row>
    <el-table ref="elTable" :data="filteredData" :row-key="getRowKey" style="width: 100%" border size="small"
      :cell-style="{ padding: '0', height: '20px' }"
      :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }">
      <el-table-column width="100">
        <template slot-scope="scope">
          <v-avatar class="my-2 rounded-lg" size="80" tile @click="previewURL(scope.row.igoalStock.imageLink)">
            <img :src="getImage(scope.row.igoalStock.imageLink)" alt="Loading" />
          </v-avatar>
        </template>
      </el-table-column>
      <el-table-column label="Date" width="100" align="center">
        <template slot-scope="scope">
          {{ formatDate(scope.row.created_date) }}
        </template>
      </el-table-column>
      <el-table-column property="igoalStock.itemCode" label="Code" align="center" :filters="filters.itemCodes"
        :filter-method="filterHandler" />
      <el-table-column property="igoalStock.itemName" label="Name" :filters="filters.itemNames"
        :filter-method="filterHandler" />
      <el-table-column property="igoalStock.itemGroup" label="Group" align="center" :filters="filters.itemGroups"
        :filter-method="filterHandler" />
      <el-table-column property="branch.code" label="Branch" align="center" :filters="filters.branches"
        :filter-method="filterHandler" />
      <el-table-column property="created_by.name" label="User" align="center" :filters="filters.users"
        :filter-method="filterHandler" />
      <el-table-column property="reqQty" label="R.Qty" />
      <el-table-column property="givenQty" label="A.Qty" />
      <el-table-column property="" label="Total Cost">
        <template slot-scope="scope">
          {{ Number(scope.row.igoalStock.costPrice) * Number(scope.row.reqQty) }}
        </template>
      </el-table-column>
      <el-table-column property="" label="Status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'COMPLETED'" type="success">Completed</el-tag>
          <el-popover placement="left" title="Remark" width="200" trigger="hover" :content="scope.row.remark">
            <el-tag slot="reference" v-if="scope.row.status === 'REJECTED'" type="danger">Rejected</el-tag>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getImage } from "../../common/utils";
import { imagePreviewHelper } from "../../config/constants";
import { requestsHistory } from "./lib";
import { DateService } from "../../common/DateService";
export default {
  name: "New_Page",
  computed: {
    filteredData() {
      let searchString = this.filters.searchString;
      const filteredRequests = this.requests.filter(function (item) {
        return (
          item.igoalStock.itemName.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.igoalStock.itemCode.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });

      this.$nextTick(() => {
        this.triggerScrollEvent();
      });

      return filteredRequests
    },
    itemsShowing() {
      if (!this.isMounted) return;
      const filteredItemsLength = this.$refs.elTable.tableData.length;
      const totalItemsLength = this.requests.length;
      return `Showing ${filteredItemsLength} / ${totalItemsLength} Items`;
    },
  },
  data() {
    return {
      requests: [],
      filters: {
        itemCodes: [],
        itemGroups: [],
        itemNames: [],
        branches: [],
        users: [],
        searchString: "",
        dates: [],
      },
      isMounted: false,
    };
  },
  methods: {
    getImage: getImage,
    formatDate: DateService.formatDate,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    triggerScrollEvent() {
      const scrollEvent = new Event('scroll');
      window.dispatchEvent(scrollEvent);
    },
    filterHandler(value, row, column) {
      return this.resolve(column["property"], row) === value;
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getRowKey(row) {
      return row.id;
    },
    async onInit() {
      let _dateQueryParam = {};
      if (this.filters.dates) {
        _dateQueryParam = {
          startDate: this.filters.dates[0],
          endDate: this.filters.dates[1],
        };
      }
      this.requests = await requestsHistory(_dateQueryParam);
      this.setFilters();
    },
    setFilters() {
      this.filters.itemCodes = [...new Set(this.requests.map((item) => item.igoalStock.itemCode))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemGroups = [...new Set(this.requests.map((item) => item.igoalStock.itemGroup))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemNames = [...new Set(this.requests.map((item) => item.igoalStock.itemName))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.branches = [...new Set(this.requests.map((item) => item.branch.code))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.users = [...new Set(this.requests.map((item) => item.created_by.name))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.onInit();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
};
</script>
<style></style>
